import React from "react";
import "../styles/works.css";
import works_altbot from "../assets/images/works_altbot.png";
import works_lunchbox from "../assets/images/works_lunchbox.png";
import works_sharit from "../assets/images/works_sharit.png";

const WorksPage = () => {
  return (
    <div id="works" className="works-page">
      <h1>Our Recent Works</h1>

      <div className="works_inner">
        <div className="works-card">
          <div className="works-card_left">
            <h4 className="works-card__title-mobile">
              Sharit - Social Carsharing App
            </h4>
            <img src={works_sharit} alt="" />
          </div>
          <div className="works-card_right">
            <h4 className="works-card__title">
              Sharit - Social Carsharing App
            </h4>
            <p className="works-card__text">
              Sharit is an app that combines the functionalities of a regular
              ride-sharing app with the social aspects of apps like Tinder.
            </p>

            <div className="works-card__tags">
              <span>Design</span>
              <span>Mockup</span>
              <span>Frontend</span>
              <span>Backend</span>
            </div>
          </div>
        </div>

        <div className="works-card">
          <div className="works-card_left">
            <h4 className="works-card__title-mobile">
              altBot - Crypto Trading Bot
            </h4>
            <img src={works_altbot} alt="" />
          </div>
          <div className="works-card_right">
            <h4 className="works-card__title">altBot - Crypto Trading Bot</h4>
            <p className="works-card__text">
              Altbot is a fully automated crypto trading bot. The app features
              an intuitive interface in the form of a dashboard.
            </p>

            <div className="works-card__tags">
              <span>Design</span>
              <span>Mockup</span>
              <span>Frontend</span>
              <span>Backend</span>
            </div>
          </div>
        </div>

        <div className="works-card">
          <div className="works-card_left">
            <h4 className="works-card__title-mobile">
              LunchBox - Landing Page
            </h4>
            <img src={works_lunchbox} alt="" />
          </div>
          <div className="works-card_right">
            <h4 className="works-card__title">LunchBox - Landing Page</h4>
            <p className="works-card__text">
              LunchBox is a modern ordering system for restauraunts to grow
              their online revenue
            </p>

            <div className="works-card__tags">
              <span>Design</span>
              <span>Mockup</span>
              <span>Frontend</span>
              <span>Backend</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorksPage;
