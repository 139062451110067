import React from "react";
import { cards } from "../assets/card_data";
import AOS from "aos";

import PricingCard from "../components/PricingCard";
import "../styles/pricing_page.css";

const PricingPage = () => {
  AOS.init();

  return (
    <div id="pricing" className="pricing__content">
      <div>
        <h1>Transparent Pricing</h1>
        <p>Detailed information on different projects budget</p>
      </div>
      <div
        className="pricing-cards"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        {cards.map((item) => (
          <PricingCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default PricingPage;
