import React, { useRef, useState } from "react";

import "../styles/contact_page.css";

const ContactPage = () => {
  const name = useRef("");
  const email = useRef("");
  const [tags, setTags] = useState([
    { id: 1, name: "Consulting", choosed: false },
    { id: 2, name: "Landing", choosed: false },
    { id: 3, name: "Info", choosed: false },
    { id: 4, name: "E-commerce(S)", choosed: false },
    { id: 5, name: "E-commerce(L)", choosed: false },
  ]);

  function handleTag(id) {
    const newTags = tags.map((obj) => {
      if (obj.id === id) {
        return {
          ...obj,
          choosed: !obj.choosed,
        };
      } else {
        return obj;
      }
    });
    return setTags(newTags);
  }

  function filterArr(arr) {
    const newArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].choosed === true) {
        newArr.push(arr[i].name);
      }
    }
    return newArr;
  }

  const addUser = async (newProduct) => {
    fetch("https://hub.altcode.kg/api/v1/project_requests", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(newProduct),
    });
  };

  return (
    <div id="contact" className="contact-page">
      <div className="contact-page__form">
        {/* <h1>Select the option and we will contact you</h1> */}
        <div className="contact-page__form_inner">
          <div>
            <h3>Choose what you need and we will contact you</h3>
            <div className="contact-page__tags">
              {tags.map((tag) => (
                <div
                  onClick={() => handleTag(tag.id)}
                  key={tag.id}
                  className={
                    tag.choosed
                      ? "contact-page__tag_choosed"
                      : "contact-page__tag"
                  }
                >
                  {tag.name}
                </div>
              ))}
            </div>
          </div>
          <div className="contact-page__inputs">
            <div>
              <p>Name</p>
              <input ref={name} type="text" name="" id="" placeholder="Alice" />
            </div>

            <div>
              <p>Email</p>
              <input
                ref={email}
                type="text"
                name=""
                id=""
                placeholder="alice@gmail.com"
              />
            </div>
            <button
              onClick={() => {
                addUser({
                  project_requests: {
                    customer_name: name.current.value,
                    customer_email: email.current.value,
                    project_type: filterArr(tags),
                  },
                });
              }}
            >
              Send Request
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
