import React from "react";
import "../styles/homepage.css";
import hero1 from "../assets/images/hero1.png";
import hero2 from "../assets/images/hero2.png";
import hero3 from "../assets/images/hero3.png";
import AOS from "aos";

const HomePage = () => {
  window.addEventListener("load", function () {
    AOS.init();
  });

  return (
    <>
      <div id="hero" className="main__content">
        <div className="main__content_text">
          <div>
            <h1>
              EVERY IDEA
              <br />
              HAS A CHANCE
            </h1>
            <p>
              Experienced design team passionate to create visualy-appealing{" "}
              <br />
              solutions to solve your business needs.
            </p>
          </div>
          <button>
            <a href="#contact">Start New Project</a>
          </button>
        </div>

        <div className="hero__images">
          <img
            // data-aos-duration="1000"
            // data-aos="fade-up-right"
            id="hero__img_1"
            src={hero1}
            alt="example"
            // width={530}
          />
          <img
            // data-aos-duration="1000"
            // data-aos="fade-up"
            id="hero__img_2"
            src={hero2}
            alt="example"
            // width={580}
          />
          <img
            // data-aos-duration="1000"
            // data-aos="fade-up-left"
            id="hero__img_3"
            src={hero3}
            alt="example"
            // width={530}
          />
        </div>
      </div>
    </>
  );
};

export default HomePage;
