import "./App.css";
import Navbar from "./components/Navbar";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import PricingPage from "./pages/PricingPage";
import WhyUsPage from "./pages/WhyUsPage";
import WorksPage from "./pages/WorksPage";
import { app } from "./services/firebase";

function App() {
  console.log(app.automaticDataCollectionEnabled);

  return (
    <div className="App">
      <Navbar />

      <HomePage />

      <PricingPage />
      <WhyUsPage />
      <WorksPage />
      <ContactPage />
    </div>
  );
}

export default App;
