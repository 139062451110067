import React, { useState } from "react";
import telegram from "../assets/images/telegram.svg";

import "../styles/navbar.css";

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 50) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      <nav className={colorChange ? "navOnScroll" : "navOffScroll"}>
        <a href="/" className="logo">
          <span>ALTCODE</span>
        </a>

        <div className="menu">
          <ol className="list">
            <li className="nav-link">
              <a href="#hero">Home</a>
            </li>

            <li className="nav-link">
              <a href="#pricing">Pricing</a>
            </li>

            <li className="nav-link">
              <a href="#why">Why Us</a>
            </li>

            <li className="nav-link">
              <a href="#works">Our Works</a>
            </li>

            {/* <li className="nav-link">
              <a href="#contact">Contact Us</a>
            </li> */}
          </ol>
        </div>

        <div className="telegram">
          <span className="nav-link-telegram">
            <a target="blank" href="https://telegram.me/badoalmaz">
              <img src={telegram} alt="" />
            </a>
          </span>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
